import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Search from './components/Search';

////import React, { useState } from 'react';
//import "bootstrap";

// Theme
import "./themes/bootstrap4_bhic/theme.scss";

// Bootstrap js
//import "./themes/bootstrap4_bhic/scripts/jquery.js";
//import "./themes/bootstrap4_bhic/scripts/popper.min.js";
//import "./themes/bootstrap4_bhic/scripts/bootstrap.js";

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Search/>} />
            <Route path='/web/portal_react' element={<Search/>} />
            <Route path='/web/portal_react/' element={<Search/>} />
            <Route path="*" element={<Search/>} />
        </Routes>
    </BrowserRouter>
);
export default App;