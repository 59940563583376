import * as React from "react";

class Header extends React.Component {

    render() {

            return (
                <header id="page_header" className="container-fluid sticky-top bg-bhic">
                    <div className="row border-bottom">
                        <div className="col">
                            <div className="row">
                                <div className="col-12 nav-height">
                                    <nav className="navbar navbar-expand navbar-light menu-main px-0 h-100 align-middle">
                                        <a className="navbar-brand" href={process.env.PUBLIC_URL} >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326 34" height="32px" width="100%" preserveAspectRatio="xMidYMid meet" className="text-white" aria-hidden="true" role="img">
                                                <g fill="currentColor">
                                                    <path d="M6.9,8.6c0-1.2-0.8-2-1.9-2.2v0c1.1-0.2,1.7-0.9,1.7-2.1c0-1.7-1.4-2.2-2.9-2.2H1.6v9.1h1.8C5.3,11.2,6.9,10.6,6.9,8.6z     M2.5,2.9h1.2c0.8,0,2,0.2,2,1.5c0,1.4-1.3,1.7-2.2,1.7h-1V2.9z M3.5,10.4H2.5V6.9h0.8C4.6,6.9,6,7,6,8.6C6,10.1,4.7,10.4,3.5,10.4    z"></path>
                                                    <path d="M14.7,11.2l-1.5-3.5c-0.3-0.6-0.4-1-1.2-1.1v0c1.1-0.1,2-0.9,2-2.1c0-1.7-1.1-2.4-2.7-2.4H9v9.1h0.9V6.9h0.7    c0.9,0,1.2,0,1.8,1.2l1.3,3.1H14.7z M10.9,6.1H9.9V2.9h0.9c1.2,0,2.3,0.1,2.3,1.6C13.1,5.7,11.9,6.1,10.9,6.1z"></path>
                                                    <path d="M17.2,8.7h4.6l1,2.4h0.9l-3.6-9.1h-1l-3.7,9.1h0.9L17.2,8.7z M19.5,3l2,5h-3.9L19.5,3z"></path>
                                                    <path d="M30.2,8.6c0-1.2-0.8-2-1.9-2.2v0C29.4,6.2,30,5.5,30,4.3c0-1.7-1.4-2.2-2.9-2.2h-2.2v9.1h1.8C28.6,11.2,30.2,10.6,30.2,8.6    z M25.9,2.9h1.2c0.8,0,2,0.2,2,1.5c0,1.4-1.3,1.7-2.2,1.7h-1V2.9z M25.8,6.9h0.8c1.3,0,2.7,0.2,2.7,1.8c0,1.4-1.4,1.8-2.6,1.8h-0.9    V6.9z"></path>
                                                    <path d="M33.2,8.7h4.6l1,2.4h0.9l-3.6-9.1h-1l-3.7,9.1h0.9L33.2,8.7z M35.5,3l2,5h-3.9L35.5,3z"></path>
                                                    <polygon points="47.6,2.1 46.7,2.1 46.7,10.1 46.7,10.1 42.1,2.1 40.9,2.1 40.9,11.2 41.8,11.2 41.8,3.4 41.9,3.4 46.4,11.2    47.6,11.2  "></polygon>
                                                    <polygon points="51.5,11.2 52.4,11.2 52.4,2.9 55.1,2.9 55.1,2.1 48.8,2.1 48.8,2.9 51.5,2.9  "></polygon>
                                                    <path d="M56.9,4.3c0-1.2,1-1.6,2-1.6c0.6,0,1.2,0.1,1.7,0.3l0.1-0.8C60,2,59.3,2,58.9,2C57.3,2,56,2.9,56,4.3    c0,2.4,4.2,2.6,4.2,4.4c0,1.3-0.9,1.9-2,1.9c-0.8,0-1.5-0.2-2-0.5L56,10.9c0.5,0.2,1.2,0.4,2.1,0.4c1.4,0,3-0.7,3-2.6    C61,6.1,56.9,6.1,56.9,4.3z"></path>
                                                    <polygon points="72.1,6.1 67.5,6.1 67.5,2.1 66.6,2.1 66.6,11.2 67.5,11.2 67.5,6.9 72.1,6.9 72.1,11.2 73,11.2 73,2.1 72.1,2.1    "></polygon>
                                                    <rect x="75.2" y="2.1" width="0.9" height="9.1"></rect>
                                                    <path d="M78.8,4.3c0-1.2,1-1.6,2-1.6c0.6,0,1.2,0.1,1.7,0.3l0.1-0.8C81.9,2,81.2,2,80.8,2c-1.6,0-2.9,0.9-2.9,2.4    c0,2.4,4.2,2.6,4.2,4.4c0,1.3-0.9,1.9-2,1.9c-0.8,0-1.5-0.2-2-0.5l-0.1,0.9c0.5,0.2,1.2,0.4,2.1,0.4c1.4,0,3-0.7,3-2.6    C83,6.1,78.8,6.1,78.8,4.3z"></path>
                                                    <polygon points="83.9,2.9 86.5,2.9 86.5,11.2 87.4,11.2 87.4,2.9 90.1,2.9 90.1,2.1 83.9,2.1  "></polygon>
                                                    <path d="M95,2c-2.7,0-4,2.2-4,4.7c0,2.8,1.4,4.7,4,4.7s4-1.9,4-4.7C99,4.2,97.7,2,95,2z M95,10.6c-2.1,0-3.1-2-3.1-3.9    c0-1.9,1-3.9,3.1-3.9s3.1,2,3.1,3.9C98.1,8.6,97.1,10.6,95,10.6z"></path>
                                                    <path d="M105,7.7c-0.3-0.6-0.4-1-1.2-1.1v0c1.1-0.1,2-0.9,2-2.1c0-1.7-1.1-2.4-2.7-2.4h-2.3v9.1h0.9V6.9h0.7c0.9,0,1.2,0,1.8,1.2    l1.3,3.1h1L105,7.7z M102.7,6.1h-0.9V2.9h0.9c1.2,0,2.3,0.1,2.3,1.6C105,5.7,103.8,6.1,102.7,6.1z"></path>
                                                    <rect x="108" y="2.1" width="0.9" height="9.1"></rect>
                                                    <path d="M115.8,8.8c0-2.7-4.2-2.7-4.2-4.5c0-1.2,1-1.6,2-1.6c0.6,0,1.2,0.1,1.7,0.3l0.1-0.8C114.8,2,114.1,2,113.6,2    c-1.6,0-2.9,0.9-2.9,2.4c0,2.4,4.2,2.6,4.2,4.4c0,1.3-0.9,1.9-2,1.9c-0.8,0-1.5-0.2-2-0.5l-0.1,0.9c0.5,0.2,1.2,0.4,2.1,0.4    C114.2,11.3,115.8,10.6,115.8,8.8z"></path>
                                                    <path d="M121.9,11.3c0.6,0,1.6-0.1,2.1-0.4l-0.1-0.8c-0.5,0.3-1.4,0.4-2,0.4c-2.4,0-3.7-1.6-3.7-3.9c0-2.2,1.3-3.9,3.7-3.9    c0.6,0,1.5,0.1,2,0.4l0.1-0.8C123.5,2,122.4,2,121.9,2c-2.8,0-4.6,1.9-4.6,4.7C117.3,9.5,119,11.3,121.9,11.3z"></path>
                                                    <polygon points="131.9,2.1 131.9,6.1 127.3,6.1 127.3,2.1 126.4,2.1 126.4,11.2 127.3,11.2 127.3,6.9 131.9,6.9 131.9,11.2    132.8,11.2 132.8,2.1  "></polygon>
                                                    <rect x="1.6" y="21.1" width="0.9" height="9.1"></rect>
                                                    <polygon points="10.3,29.1 10.3,29.1 5.7,21.1 4.5,21.1 4.5,30.2 5.4,30.2 5.4,22.4 5.5,22.4 10,30.2 11.2,30.2 11.2,21.1    10.3,21.1  "></polygon>
                                                    <polygon points="13.3,30.2 14.2,30.2 14.2,25.9 17.4,25.9 17.4,25.1 14.2,25.1 14.2,21.9 17.6,21.9 17.6,21.1 13.3,21.1  "></polygon>
                                                    <path d="M22.8,21c-2.7,0-4,2.2-4,4.7c0,2.8,1.4,4.7,4,4.7c2.6,0,4-1.9,4-4.7C26.8,23.2,25.5,21,22.8,21z M22.8,29.6    c-2.1,0-3.1-2-3.1-3.9c0-1.9,1-3.9,3.1-3.9c2.1,0,3.1,2,3.1,3.9C25.9,27.6,24.9,29.6,22.8,29.6z"></path>
                                                    <path d="M31.7,25.6L31.7,25.6c1.1-0.2,2-0.9,2-2.1c0-1.7-1.1-2.4-2.7-2.4h-2.3v9.1h0.9v-4.3h0.7c0.9,0,1.2,0,1.8,1.2l1.3,3.1h1    l-1.5-3.5C32.6,26.1,32.4,25.7,31.7,25.6z M30.5,25.1h-0.9v-3.2h0.9c1.2,0,2.3,0.1,2.3,1.6C32.8,24.7,31.6,25.1,30.5,25.1z"></path>
                                                    <polygon points="40.7,29.1 37.4,21.1 36,21.1 36,30.2 36.9,30.2 36.9,22 36.9,22 40.3,30.2 41.1,30.2 44.4,22 44.4,22 44.4,30.2    45.3,30.2 45.3,21.1 44,21.1  "></polygon>
                                                    <path d="M50.4,21.1l-3.7,9.1h0.9l1-2.4h4.6l1,2.4H55l-3.6-9.1H50.4z M48.8,27l2-5l2,5H48.8z"></path>
                                                    <polygon points="54.5,21.9 57.1,21.9 57.1,30.2 58,30.2 58,21.9 60.7,21.9 60.7,21.1 54.5,21.1  "></polygon>
                                                    <rect x="61.9" y="21.1" width="0.9" height="9.1"></rect>
                                                    <polygon points="65.9,25.9 69.2,25.9 69.2,25.1 65.9,25.1 65.9,21.9 69.4,21.9 69.4,21.1 65,21.1 65,30.2 69.6,30.2 69.6,29.4    65.9,29.4  "></polygon>
                                                    <path d="M79.4,21.7c0.6,0,1.5,0.1,2,0.4l0.1-0.8C81,21,79.9,21,79.4,21c-2.8,0-4.6,1.9-4.6,4.7c0,2.8,1.7,4.7,4.6,4.7    c0.6,0,1.6-0.1,2.1-0.4l-0.1-0.8c-0.5,0.3-1.4,0.4-2,0.4c-2.4,0-3.7-1.6-3.7-3.9C75.7,23.4,77,21.7,79.4,21.7z"></path>
                                                    <polygon points="84.8,25.9 88.2,25.9 88.2,25.1 84.8,25.1 84.8,21.9 88.4,21.9 88.4,21.1 83.9,21.1 83.9,30.2 88.5,30.2 88.5,29.4    84.8,29.4  "></polygon>
                                                    <polygon points="96.2,29.1 96.1,29.1 91.5,21.1 90.4,21.1 90.4,30.2 91.3,30.2 91.3,22.4 91.3,22.4 95.8,30.2 97.1,30.2 97.1,21.1    96.2,21.1  "></polygon>
                                                    <polygon points="98.3,21.9 100.9,21.9 100.9,30.2 101.8,30.2 101.8,21.9 104.5,21.9 104.5,21.1 98.3,21.1  "></polygon>
                                                    <path d="M108.8,25.6L108.8,25.6c1.1-0.2,2-0.9,2-2.1c0-1.7-1.1-2.4-2.7-2.4h-2.3v9.1h0.9v-4.3h0.7c0.9,0,1.2,0,1.8,1.2l1.3,3.1h1    l-1.5-3.5C109.7,26.1,109.5,25.7,108.8,25.6z M107.7,25.1h-0.9v-3.2h0.9c1.2,0,2.3,0.1,2.3,1.6C109.9,24.7,108.7,25.1,107.7,25.1z"></path>
                                                    <path d="M118.7,27c0,1.5-0.8,2.5-2.4,2.5c-1.6,0-2.4-1.1-2.4-2.5v-5.9H113V27c0,2.7,2,3.3,3.3,3.3c1.3,0,3.3-0.7,3.3-3.3v-5.9h-0.9    V27z"></path>
                                                    <polygon points="126.5,29.1 123.2,21.1 121.8,21.1 121.8,30.2 122.7,30.2 122.7,22 122.8,22 126.2,30.2 126.9,30.2 130.2,22    130.3,22 130.3,30.2 131.2,30.2 131.2,21.1 129.9,21.1  "></polygon>
                                                </g>
                                                <g fill="currentColor">
                                                    <path d="M262.6,11.5c-3.4,0-5.4,1-7.2,4.1c0.2-1.2,0.2-2.4,0.2-3.7V1h-6.9v31.8h6.7v-3.4c1.8,2.8,4.2,4.2,7,4.2    c5.7,0,9.5-4.2,9.5-11.1C271.9,15.8,268,11.5,262.6,11.5z M259.8,27.7c-2.9,0-4.7-2-4.7-5.2s1.8-5.2,4.7-5.2c2.9,0,4.8,2,4.8,5.2    S262.8,27.7,259.8,27.7z"></path>
                                                    <path d="M288.8,11.4c-3.5,0-5.8,1.4-7.7,4.5c0.2-1.1,0.3-2.4,0.3-3.7V1h-7.2h-0.1v31.8h7.2v-8.7c0-4.6,1.1-7.1,4.4-7.1    c2.4,0,3.4,1.5,3.4,4.7v11h7.3V20.9c0-3-0.1-5.2-1.5-7C293.7,12.3,291.5,11.4,288.8,11.4z"></path>
                                                    <rect x="299.7" y="12.1" width="7.3" height="20.7"></rect>
                                                    <path d="M303.4,1.6c-2.2,0-4,1.8-4,4s1.7,4.1,4,4.1c2.2,0,4-1.8,4-4.1C307.4,3.4,305.6,1.6,303.4,1.6z"></path>
                                                    <path d="M321.3,17.1c1.5,0,2.7,0.6,4.1,1.8v-6.2c-1.6-1-3.2-1.3-5.2-1.3c-6.3,0-10.8,4.5-10.8,11c0,6.9,4.6,11.1,10.7,11.1    c1.9,0,3.6-0.5,5.3-1.4v-6.3c-1.2,1.3-2.3,1.8-3.9,1.8c-3.1,0-4.9-2.1-4.9-5.2C316.6,19.1,318.5,17.1,321.3,17.1z"></path>
                                                </g>
                                                <g fill="currentColor" fillOpacity="0.6">
                                                    <rect x="149.4" y="1" width="3.8" height="31.8"></rect>
                                                    <rect x="158.7" y="1" width="3.8" height="31.8"></rect>
                                                    <rect x="210.6" y="1" width="8.8" height="31.8"></rect>
                                                    <rect x="233.8" y="1" width="8.8" height="31.8"></rect>
                                                    <rect x="174.9" y="1" width="11.6" height="31.8"></rect>
                                                    <rect x="197.4" y="1" width="8.8" height="31.8"></rect>
                                                </g>

                                            </svg>
                                        </a>
                                        <div className="ml-auto mr-5 font-ptsans text-size-large">
                                            <span>Vragen? Mail naar</span> <a className="text-white" href="mailto:info@bhic.nl">info@bhic.nl</a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            );

    }
};

export default Header;